/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file DelayReduxInput.tsx
 * @desc Created on Tue May 24 2022 19:06:43
 * @copyright All rights reserved @ Inarix
 */
import { FC, FormEventHandler, useEffect, useState } from 'react';
import { DelayedInputParams } from '../../../declarations/DelayedInputParams';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { TextField } from '@mui/material';

const DelayReduxInput: FC<DelayedInputParams> = (props) => {
  const dispatch = useAppDispatch();
  const changed = useAppSelector(props.isModifiedSelector);
  const disabled = 'pending' === useAppSelector(props.pendingSelector);
  const [value, setValue] = useState(useAppSelector(props.valueSelector));
  const { action } = props;

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      dispatch(action(value as string));
    }, 120);
    return (): void => window.clearTimeout(timeout);
  }, [dispatch, action, value]);

  const inputHandler: FormEventHandler = (e) => {
    setValue((e.currentTarget as HTMLInputElement).value);
  };

  return (
    <TextField
      variant="outlined"
      required
      size="small"
      error={changed && !value}
      value={value}
      type={props.type}
      label={props.placeholder}
      disabled={disabled}
      onChange={inputHandler}
      {...props.mui}
    />
  );
};

export default DelayReduxInput;
