/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file Job.ts
 * @desc Created on Sat May 28 2022 02:23:15
 * @copyright All rights reserved @ Inarix
 */
import IndexedMap from '../redux/utils/IndexedMap';
import { JobStatus } from './Constant';
import {
  Image,
  ImageFromAPI,
  compositeIdFactory,
  ImageWithEagerFromAPI,
  toImageFromAPI,
} from './Image';
import { PastAction } from './PastAction';

export interface Job {
  id: string;
  assignedUser?: string;
  userGroup?: number;
  jobSupervisor?: string;
  jobSupervisorsGroup?: number;
  relatedPreviousJob?: string;

  name: string;
  userFacingName?: string;
  shortInstructions?: string;
  docFileLocationId?: number;
  tags?: string[];

  expireAt?: string;
  activityDurationSec?: number;
  pastActionId: string;
  scenarioId?: number;

  creatorId: string;
  createdAt: string;
  updatedAt: string;
  assignedAt?: string;
  archivedAt?: string;
  archivedBy?: string;
  createdAtMs?: number; // temporary value for local maths

  statusId: JobStatus;
  userComments?: string[];

  images?: string[];
  objectViews?: string[];
  samples?: string[];
  labelTemplates?: string[];
  labelTemplateConfig?: {};

  showGrainsLabels: boolean;
  showSampleLabels: boolean;
  showImageLabels: boolean;
  showSampleInfo: boolean;
  showRelatedPredictionObj: boolean;
  noIsolationForLabels: boolean;

  canUpdatePreviousGrainsLabels: boolean;
  canUpdatePreviousSampleLabels: boolean;
  canUpdatePreviousImageLabels: boolean;

  useQdrant: boolean;
}

export interface FileLocation {
  id: number;
  uri: string;
  isUploaded: boolean;
  mimeType: string;
  bucket: string;
  region: string;
  signedUrl: string; // this one is generated afterwards
}
export interface Acquisition {
  id: string;
  sampleId: string;
  originPastActionId: string;
  originScenarioInstanceId: number;
}

export interface AcquisitionWithEager extends Acquisition {
  images: ImageFromAPI[];
}

export interface Scenario {
  id: number;
  name: string;
  description?: string;
  cerealTypeId: number;
  slug: string;
  iconSalt: number;
  status: number;
  statusConstantId: number | null;
  lastScenarioInstanceId: number | null;
  isSystemScenario: boolean;
  creatorId: string;
  ownerOrgId: number;
  archivedAt?: string;
  deletedAt?: string;
}

export interface Sample {
  id: string;
  originPastActionId: string;
  originScenarioInstanceId: number;
}

export interface SampleWithEager extends Sample {
  acquisitions: AcquisitionWithEager[];
}

// TODO wait for eslint to fix their tuple issue
export interface JobWithEager extends Job {
  imagesData?: ImageWithEagerFromAPI[];
  objectViewsData?: {
    id: string;
    objectId: string;
    imageProcessorId: number;
    imageObjectIdx: number;
    boundingBox: number[];
    derivedImage: ImageFromAPI;
    image: ImageFromAPI;
  }[];
  scenario?: Scenario;
  samplesData?: SampleWithEager[];
  pastAction?: PastAction;
  docFileLocation?: FileLocation;
}

export function parseJob(job: JobWithEager): {
  samples: string[];
  objViews: Record<string, boolean>;
  images: IndexedMap<Image>;
} {
  const images: IndexedMap<Image> = new IndexedMap();
  const samples: Record<string, boolean> = {};
  const objViews: Record<string, boolean> = {};

  job.imagesData?.forEach((img) => {
    images.push(img.id, toImageFromAPI(img));
    if (img.acquisition?.sampleId) {
      samples[img.acquisition.sampleId] = true;
    }
  });

  job.objectViewsData?.forEach((obj) => {
    if (obj.derivedImage) {
      const img = obj.derivedImage;

      if (obj.imageObjectIdx) {
        objViews[obj.id] = true;
        images.push(
          compositeIdFactory(img.id, obj.imageObjectIdx),
          toImageFromAPI(img, {
            objectIdx: obj.imageObjectIdx,
            file: `object_${obj.imageObjectIdx}.jpg`,
            objectViewId: obj.id,
            objectId: obj.objectId,
            originId: obj.image.id,
            box: obj.boundingBox,
          }),
        );
      }

      if (!images.has(obj.image.id)) {
        images.ref(obj.image.id, toImageFromAPI(obj.image));
      }
    }
  });
  job.samplesData?.forEach((sample) => {
    samples[sample.id] = true;
    sample.acquisitions.forEach((acq) =>
      acq.images.forEach((img) =>
        images.push(
          img.id,
          toImageFromAPI(img, {
            sampleId: acq.sampleId,
          }),
        ),
      ),
    );
  });

  return {
    samples: Object.keys(samples),
    // objViews: Object.keys(objViews),
    objViews,
    images,
  };
}
